<div class="container center-content">
  <div class="well-login  col-lg-8 col-md-10">
    <h2>Do you already have a WordFlight account?</h2>
    <p>
      It looks like this is your first time logging into WordFlight from Classlink.
      If you already have an account, you can link it by entering your WordFlight credentials.
      Otherwise you can create a new WordFlight account.
    </p>
    <div class="link-container">
      <div class="link-choice">
        <h4>Link Existing Account</h4>
        <form [formGroup]="linkAccountForm" (ngSubmit)="linkAccount()">
          <div class="row">
            <div class="col-xs-12">
              <div class="inner-addon left-addon">
                <i class="glyphicon glyphicon-user"></i>
                <input type="text" style="margin-bottom: 10px;"
                       class="form-control" formControlName="username" [attr.disabled]="linkButtonDisabled ? 1 : null"
                       name="username" placeholder="Username"/>
              </div>
            </div>
            <div class="col-xs-12">
              <div class="inner-addon left-addon">
                <span class="glyphicon glyphicon-lock"></span>
                <input type="password" class="form-control" name="password"
                       [attr.disabled]="linkButtonDisabled ? 1 : null" formControlName="password"
                       placeholder="Password"/>
              </div>
            </div>
          </div>
          <div class="row button-row">
            <div class="col-xs-12">
              <div class="inner-addon left-addon">
                <i class="glyphicon glyphicon-link"></i>
                <button
                  type="submit"
                  class="btn btn-primary btn-block btn-success"
                  [ngClass]="{disabled: linkButtonDisabled || !linkAccountForm.valid}"
                  [disabled]="!linkAccountForm.valid || linkButtonDisabled"
                >
                  Link
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="divider">
        <div class="vert"></div>
        <div class="content">OR</div>
        <div class="vert"></div>
      </div>
      <div class="link-choice" style="text-align: center;">
        <h4>Create A New Account</h4>
        <div class="row button-row create-button-row">
          <div class="col-xs-12">
            <div class="inner-addon left-addon">
              <span class="glyphicon glyphicon-plus"></span>
              <button class="btn btn-primary btn-block btn-success" ngClass="{disabled: linkCleverCtrl.linkButtonDisabled}" (click)="createAccount()" [disabled]="linkButtonDisabled">Create</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showError" class="alert alert-danger" role="alert">
      {{errorMessage}}
    </div>
  </div>
</div>
