import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactSupportService {

  constructor(
    private httpClient: HttpClient,
  ) {}

  sendEmail(form: any): Observable<any> {
    const reqOpts = {
      headers: {
        'Access-Control-Allow-Origin' : '*',
        'Content-Type':'application/json'
      }
    } ;

    let body = {
      "email-content": {
        "sender-name": form.name,
        "topic": form.topic.name,
        "body": form.message,
        "contact": form.contactPreference == 'email' ? form.email : form.phone
    }
  };

  return  this.httpClient.post(environment.dataServicesURL + 'support/contact', body, reqOpts);  
  }

}
