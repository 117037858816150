import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CleverService } from '../core/services/clever.service';
import { DialogService } from '../core/services/dialog.service';
import { EnrollmentService } from '../core/services/enrollment.service';
import { SessionStorageService } from '../core/services/session-storage.service';

// CONSTANTS
const OTHER_GRADE_VAL = 14;

@Component({
  selector: 'wf-roster-clever',
  templateUrl: './roster-clever.component.html'
})
export class RosterCleverComponent implements OnInit {
  showWaitDialog: boolean = true;
  showAddingDialog: boolean = false;
  showError: boolean = false;
  errorMessage: string = '';
  isAllSelected: boolean = false;
  gradeOptions: any[] = [];
  sections: any[] = [];
  sectionData: any[] = [];

  // Roster sections form
  rosterForm = this.fb.group({
    rosterSections: this.fb.array([])
  });

  constructor(
    private sessionStorageService: SessionStorageService,
    private enrollmentService: EnrollmentService,
    private cleverService: CleverService,
    private dialogService: DialogService,
    private router: Router,
    private fb: FormBuilder
  ) { }

  get rosterSections() {
    return this.rosterForm.controls["rosterSections"] as FormArray;
  }

  ngOnInit(): void {
    // check that sessionStorage.sso is set
    if (this.sessionStorageService.getSSOInfo() === undefined) {
      this.errorMessage = "We are unable to retrieve your Clever section information at this time. " +
                      "Please make sure you are logged in with Clever.";
      this.showWaitDialog = false;
      this.showAddingDialog = false;
      this.showError = true;
      return;
    }
    this.enrollmentService.getGradeOptions().subscribe((data: any) => {
      for (let i = 0; i < data.length; i++) {
        this.gradeOptions.push(data[i]);
      }
    });

    this.cleverService.getSections().subscribe({
      next: (response: any) => {
        this.sectionData = JSON.parse(response.data).data;

        for (let section of this.sectionData) {
          let gradeVal: number | string = '';
          for (var key in this.gradeOptions) {
            if(this.gradeOptions[key].value === section.data.grade) {
              gradeVal = this.gradeOptions[key].key;
            }
          }
          if (gradeVal === '') {
            gradeVal = OTHER_GRADE_VAL;
          }
          const sectionForm = this.fb.group({
            name: section.data.name,
            id: section.data.id,
            grade: [{value: gradeVal, disabled: true}],
            checked: false
          });

          this.rosterSections.push(sectionForm);
        }
        this.showWaitDialog = false;
      },
      error: () => {
        this.errorMessage = "We are unable to retrieve your Clever section information at this time."
        this.showWaitDialog = false;
        this.showError = true;
      }
    });
  }

  updateSection(index: number) {
    // When section checked, check to disable grade, and check if all are selected
    const isChecked = this.rosterSections.controls[index].get('checked')?.value;
    const gradeControl = this.rosterSections.controls[index].get('grade');
    isChecked ? gradeControl?.enable() : gradeControl?.disable();
    this.isAllSelected = this.rosterSections.controls.every(element => {
      return element.value.checked;
    });
  }

  toggleAll(event: any) {
    let toggleStatus = event.target.checked;
    this.rosterSections.controls.forEach((section) => {
      section.patchValue({checked: toggleStatus});
      // also enable or disable the grade control
      toggleStatus ? section.get('grade')?.enable() : section.get('grade')?.disable();
    });
    this.isAllSelected = toggleStatus;
  }

  submitSections() {
    //Generate the object to send
    this.showAddingDialog = true;
    let requestData = [];
    for (let section of this.rosterSections.controls) {
      if (section.get('checked')?.value) {
        requestData.push({
          name: section.get('name')?.value,
          id: section.get('id')?.value,
          grade: parseInt(section.get('grade')?.value, 10),
          checked: section.get('checked')?.value,
        });
      }
    }

    this.cleverService.sendSectionsToEnroll(requestData).subscribe({
      next: (response: any) => {
        if (response.numberDuplicate > 0 && response.duplicateStudentList.length > 0) {
          this.dialogService.setSectionAddMessage(response);
        }

        let userInfo = this.sessionStorageService.getUserData() ;
        if (userInfo && !userInfo.fullProductEnabled && !userInfo.diagnosticProductEnabled && (userInfo.numberOfUserLogins && userInfo.numberOfUserLogins < 2))
        {
          this.router.navigateByUrl('/resources/screener/view/welcome', { state: { resourceUrl: 'html/welcome.html', videoUrl: '/assets/resources/videos/welcome.mp4' }}) ;
        }
        else
        {
          this.router.navigateByUrl('students');
        }
      },
      error: () => {
        this.showAddingDialog = false;
        console.log("There was an error adding students from Clever.")
      }
    });
  }

  skipSections() {
    let userInfo = this.sessionStorageService.getUserData() ;
    if (userInfo && !userInfo.fullProductEnabled && !userInfo.diagnosticProductEnabled &&
      (userInfo.numberOfUserLogins && userInfo.numberOfUserLogins < 2))
    {
      this.router.navigateByUrl('/resources/screener/view/welcome', { state: { resourceUrl: 'html/welcome.html', videoUrl: '/assets/resources/videos/welcome.mp4' }}) ;
    }
    else
    {
      this.router.navigateByUrl('students');
    }
  }
}
