import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import {
  faArrowLeft,
  faCircleCheck,
  faCircleExclamation,
  faPaperPlane,
  faRotateLeft
} from '@fortawesome/free-solid-svg-icons';
import { LoginService } from '../../core/services/login.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  resetIcon = faRotateLeft ;
  resetSuccessIcon = faCircleCheck ;
  resetErrorIcon = faCircleExclamation ;
  emailIcon = faPaperPlane ;
  backIcon = faArrowLeft ;
  error: string = '' ;
  showResetFields: boolean = true ;
  resetError: boolean = false ;
  resetSuccess: boolean = false ;
  forgotPasswordForm = this.fb.group({
    emailAddress: ['', [ Validators.required, Validators.email ]]
  });

  constructor(
    private loginService: LoginService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
  }

  get enteredEmail() {
    return this.forgotPasswordForm.get('emailAddress')?.value ;
  }

  sendResetLink() {
    if (!this.enteredEmail) return ;

    this.loginService.requestPasswordReset(this.enteredEmail).subscribe({
      next: () => {
        this.showResetFields = false ;
        this.resetSuccess = true ;
        this.resetError = false ;
      },
      error: (error) => {
        let errorCode = error.status ?? -1 ;
        this.showResetFields = false ;
        this.resetSuccess = false ;
        this.resetError = true ;

        if (errorCode >= 500 || errorCode === -1)
        {
          this.error = 'The application is temporarily unavailable. Please wait a few moments and try again.' ;
        }
        else
        {
          this.error = 'The request to reset your password encountered an error. Please wait a few moments and try again.' ;
        }
      }
    })
  }
}
