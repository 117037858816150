import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';

import { UserRoleGuard } from './core/guards/user-role.guard';
import { UserRoleGroups, UserRoles } from './core/models/user.model';
import { StudentsResolver } from './students/shared/students.resolver';
import { EnrollmentResolver } from './enrollment/student-enrollment/enrollment.resolver';
import { StudentEditResolver } from './enrollment/student-enrollment/student-edit.resolver';
import { ResourcesComponent } from './resources/resources.component' ;
import { ForgotPasswordComponent } from './logins/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './logins/reset-password/reset-password.component';
import { LoginComponent } from './logins/login/login.component';
import { SettingsComponent } from './settings/settings.component';
import { EnrollmentComponent } from './enrollment/student-enrollment/enrollment.component';
import { StudentsListComponent } from './students/students-list/students-list.component';
import { ScreenerComponent } from './reports/screener/screener.component';
import { SystemDashboardComponent } from './reports/system/dashboards/system/system-dashboard.component';
import { ScreenerResolver } from './reports/screener/screener.resolver';
import { SystemDashboardResolver } from './reports/system/dashboards/system/system-dashboard.resolver';
import { SchoolTeacherResolver } from './shared/components/school-teacher-filter/school-teacher.resolver';
import { CleverComponent } from './logins/clever/clever.component';
import { LinkCleverComponent } from './link-clever/link-clever.component';
import { RosterCleverComponent } from './roster-clever/roster-clever.component';
import { ParentDashboardComponent } from './reports/system/dashboards/parent/parent-dashboard.component';
import { ParentDashboardResolver } from './reports/system/dashboards/parent/parent-dashboard.resolver';
import { GroupSummaryComponent } from './reports/system/summary/group/group.component';
import { GroupSummaryResolver } from './reports/system/summary/group/group.resolver';
import { ObjectivePrePostComponent } from './reports/system/objective/objective.component';
import { ObjectiveResolver } from './reports/system/objective/objective.resolver';
import { SessionSummaryComponent } from './reports/system/summary/session/session.component';
import { SessionSummaryResolver } from './reports/system/summary/session/session.resolver';
import { UnitDetailComponent } from './reports/system/unit/detail/unit-detail.component';
import { UnitDetailResolver } from './reports/system/unit/detail/unit-detail.resolver';
import { AssignDiagnosticsComponent } from './subscriptions/assign-diagnostics/assign-diagnostics.component';
import { DiagnosticResolver } from './reports/system/diagnostic/diagnostic.resolver';
import { DiagnosticDetailComponent } from './reports/system/diagnostic/detail/detail.component';
import { SubscriptionTypes } from './core/models/subscription-types.model';
import { DiagnosticReportComponent } from './reports/system/diagnostic/diagnostic.component';
import { DistrictScreenerComponent } from './reports/district/screener/screener.component';
import { DistrictScreenerResolver } from './reports/district/screener/screener.resolver';
import { DistrictUsageResolver } from './reports/district/usage/usage.resolver';
import { DistrictUsageComponent } from './reports/district/usage/usage.component';
import { DistrictProgressComponent } from './reports/district/progress/progress.component';
import { DistrictProgressResolver } from './reports/district/progress/progress.resolver';
import { DistrictDiagnosticResolver } from './reports/district/diagnostic/diagnostic.resolver';
import { DistrictDiagnosticComponent } from './reports/district/diagnostic/diagnostic.component';
import { AssignDiagnosticsResolver } from './subscriptions/assign-diagnostics/assign-diagnostics.resolver';
import { DiagnosticScheduleComponent } from './subscriptions/diagnostic-schedule/diagnostic-schedule.component';
import { ResourcesCategoryComponent } from './resources/category-select/category-select.component';
import { HtmlResourceResolver } from './resources/html/html.resolver';
import { HtmlResourcesComponent } from './resources/html/html.component';
import { BatchEnrollmentComponent } from './enrollment/batch-enrollment/batch-enrollment.component';
import { ContactComponent } from './support/contact/contact.component';
import { SurveyComponent } from './survey/survey/survey.component';
import { StudentSummaryComponent } from './reports/system/summary/student/student.component';
import { StudentSummaryResolver } from './reports/system/summary/student/student.resolver';
import { SurveyResolver } from './survey/survey/survey.resolver';
import { ClasslinkComponent } from './logins/classlink/classlink.component';
import { LinkClasslinkComponent } from './link-classlink/link-classlink.component';
import { RosterClasslinkComponent } from './roster-classlink/roster-classlink.component';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [ AuthGuard, UserRoleGuard ],
    children: [
      // NOTE: All children go through the AuthGuard.  Any route with the data attribute
      //       'needs_auth' set to false bypasses the need to be logged in, otherwise 'needs_auth'
      //       is implicitly set to true (needing authentication)
      { path: 'login', component: LoginComponent, data: { needs_auth: false } },
      { path: 'clever/login', component: CleverComponent, data: { needs_auth: false} },
      { path: 'classlink/login', component: ClasslinkComponent, data: { needs_auth: false} },
      { path: 'forgot-password', component: ForgotPasswordComponent, data: { needs_auth: false } },
      { path: 'password/reset/:token', component: ResetPasswordComponent, data: { needs_auth: false } },
      { path: 'clever/link', component: LinkCleverComponent, data: { needs_auth: false } },
      { path: 'classlink/link', component: LinkClasslinkComponent, data: { needs_auth: false } },
      { path: 'settings', component: SettingsComponent },
      { path: 'student/add', component: EnrollmentComponent, data: { rolesAllowed: UserRoleGroups.TEACHERS_PARENTS }, resolve: { enrollData: EnrollmentResolver } },
      { path: 'student/edit/:id',
        component: EnrollmentComponent,
        data: { rolesAllowed: UserRoleGroups.TEACHERS_PARENTS },
        resolve: {
          enrollData: EnrollmentResolver,
          studentData: StudentEditResolver
        }
      },
      { path: 'students', component: StudentsListComponent, data: { rolesAllowed: UserRoleGroups.TEACHERS_PARENTS }, resolve: { resolveData: StudentsResolver } },
      { path: 'students/manage-subscriptions', component: AssignDiagnosticsComponent, data: { rolesAllowed: UserRoleGroups.TEACHERS }, resolve: { resolveData: AssignDiagnosticsResolver }, runGuardsAndResolvers: 'always' },
      { path: 'students/manage-subscriptions/scheduling', component:DiagnosticScheduleComponent, data: { rolesAllowed: UserRoleGroups.TEACHERS } },
      { path: 'reports/screener', component: ScreenerComponent, data: { rolesAllowed: UserRoleGroups.NOT_PARENTS_NOT_CUSTOMER_SERVICE }, resolve: { resolveData: ScreenerResolver, filterData: SchoolTeacherResolver } },
      { path: 'reports/system/summary/group', component: GroupSummaryComponent, data: { rolesAllowed: UserRoleGroups.NOT_PARENTS_NOT_CUSTOMER_SERVICE }, resolve: { resolveData: GroupSummaryResolver, filterData: SchoolTeacherResolver } },
      { path: 'reports/system/summary/session', component: SessionSummaryComponent, data: { rolesAllowed: UserRoleGroups.NOT_PARENTS_NOT_CUSTOMER_SERVICE }, resolve: { resolveData: SessionSummaryResolver, filterData: SchoolTeacherResolver } },
      { path: 'reports/system/summary/student', component: StudentSummaryComponent, data: { rolesAllowed: UserRoleGroups.NOT_PARENTS_NOT_CUSTOMER_SERVICE }, resolve: { resolveData: StudentSummaryResolver, filterData: SchoolTeacherResolver } },
      { path: 'reports/system/objective', component: ObjectivePrePostComponent, data: { rolesAllowed: UserRoleGroups.NOT_PARENTS_NOT_CUSTOMER_SERVICE }, resolve: { resolveData: ObjectiveResolver, filterData: SchoolTeacherResolver }  },
      { path: 'reports/system/unit-detail', component: UnitDetailComponent, data: { rolesAllowed: UserRoleGroups.NOT_PARENTS_NOT_CUSTOMER_SERVICE }, resolve: { resolveData: UnitDetailResolver, filterData: SchoolTeacherResolver }  },
      { path: 'reports/system/diagnostic', component: DiagnosticReportComponent, data: { rolesAllowed: UserRoleGroups.NOT_CUSTOMER_SERVICE, product: SubscriptionTypes.FullProduct }, resolve: { resolveData: DiagnosticResolver, filterData: SchoolTeacherResolver }  },
      { path: 'reports/:product/diagnostic/:category/detail/:userid', component: DiagnosticDetailComponent, data: { rolesAllowed: UserRoleGroups.NOT_CUSTOMER_SERVICE } },
      { path: 'reports/diagnostic/diagnostic', component: DiagnosticReportComponent, data: { rolesAllowed: UserRoleGroups.NOT_CUSTOMER_SERVICE, product: SubscriptionTypes.DiagnosticProduct }, resolve: { resolveData: DiagnosticResolver, filterData: SchoolTeacherResolver }  },
      { path: 'reports/district/screener', component: DistrictScreenerComponent, data: { rolesAllowed: UserRoleGroups.DISTRICT_REPORTS }, resolve: { resolveData: DistrictScreenerResolver, filterData: SchoolTeacherResolver }  },
      { path: 'reports/district/usage', component: DistrictUsageComponent, data: { rolesAllowed: UserRoleGroups.DISTRICT_REPORTS }, resolve: { resolveData: DistrictUsageResolver, filterData: SchoolTeacherResolver }  },
      { path: 'reports/district/progress', component: DistrictProgressComponent, data: { rolesAllowed: UserRoleGroups.DISTRICT_REPORTS }, resolve: { resolveData: DistrictProgressResolver, filterData: SchoolTeacherResolver }  },
      { path: 'reports/district/diagnostic', component: DistrictDiagnosticComponent, data: { rolesAllowed: UserRoleGroups.DISTRICT_REPORTS }, resolve: { resolveData: DistrictDiagnosticResolver, filterData: SchoolTeacherResolver }  },
      { path: 'dashboard/system', component: SystemDashboardComponent, data: { rolesAllowed: UserRoleGroups.NOT_PARENTS_NOT_CUSTOMER_SERVICE }, resolve: { resolveData: SystemDashboardResolver, filterData: SchoolTeacherResolver } },
      { path: 'clever/roster', component: RosterCleverComponent, data: { rolesAllowed: UserRoleGroups.TEACHERS_PARENTS }},
      { path: 'classlink/roster', component: RosterClasslinkComponent, data: { rolesAllowed: UserRoleGroups.TEACHERS_PARENTS }},
      { path: 'dashboard/home', component: ParentDashboardComponent, data: { rolesAllowed: UserRoleGroups.PARENTS }, resolve: { resolveData: ParentDashboardResolver } },
      { path: 'resources/:product', component: ResourcesComponent, data: { rolesAllowed: UserRoleGroups.RESOURCES} },
      { path: 'resources/:product/list/:group', component: ResourcesCategoryComponent, data: { rolesAllowed: UserRoleGroups.RESOURCES} },
      { path: 'resources/:product/:group/:category', component: ResourcesComponent, data: { rolesAllowed: UserRoleGroups.RESOURCES} },
      { path: 'resources/:product/all/view/:resource', component: HtmlResourcesComponent, data: { rolesAllowed: UserRoleGroups.RESOURCES}, resolve: { resolveData: HtmlResourceResolver } },
      { path: 'batch-enrollment', component: BatchEnrollmentComponent, data: { rolesAllowed: UserRoles.CUSTOMER_SERVICE} },
      { path: 'support/contact', component: ContactComponent, data: { rolesAllowed: UserRoleGroups.NOT_CUSTOMER_SERVICE } },
      { path: 'survey', component: SurveyComponent, data: { rolesAllowed: UserRoleGroups.CUSTOMERS }, resolve: { resolveData: SurveyResolver } },

      // Redirect to the login screen if there is no path match.
      { path: '**', redirectTo: '/login', pathMatch: 'full' },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
