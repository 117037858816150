import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import * as _ from 'underscore';

import { faPrint } from '@fortawesome/free-solid-svg-icons';

import { ManagementUser } from '../../../core/models/management-user.model';
import { District } from '../../../core/models/district.model';
import { School } from '../../../core/models/school.model';
import { ReportsService } from '../../../core/services/reports.service';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { PrintService } from '../../../core/services/print.service';

@Component({
  selector: 'wf-district-usage',
  templateUrl: './usage.component.html',
  styleUrls: ['./usage.component.css']
})
export class DistrictUsageComponent implements OnInit {
  currentUser: ManagementUser | null = null ;
  showSchoolFilter: boolean = false ;
  areSessionsForAcademicYear: boolean = false ;
  schoolFilterError: string = '' ;
  isSchoolReportsUser: boolean = false;
  loadError: string = '' ;
  districts: District[] = [] ;
  schools: School[] = [] ;
  teachers: ManagementUser[] = [] ;
  showTeacherTable: boolean = false ;
  showSchoolTable: boolean = false ;
  selectedDistrictId: number = -1 ;
  selectedSchoolId: number = 0 ;
  reportData: any ;
  rangeData: any ;
  enrolled: number = 0 ;
  startedPercent: string = '' ;
  loginPercentDisplay: string = '' ;
  multiLoginPercentDisplay: string = '' ;
  avgWeeklyUsage: number = 0 ;
  dateRanges: any[] = [] ;
  dateRange: any = {} ;
  schoolTableSwitch: string = '+' ;
  teacherTableSwitch: string = '+' ;
  schoolCols: string[] = [
    'School',
    'Number of Teachers',
    'WordFlight Students',
    '% students hitting min weekly time goal'
  ] ;
  schoolAggregateData: any = [] ;
  teacherCols: string[] = [
    'First Name',
    'Last Name',
    'Grade',
    'School',
    '# of Teacher Logins',
    '% students hitting min weekly time goal'
  ] ;
  teacherAggregateData: any = [] ;
  trendType: ChartType = 'line' ;
  trendData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [ ],
        borderColor: 'rgba(127, 127, 127, 1)',
        borderWidth: 2,
        pointRadius: 0,
        fill: false,
      },
      {
        data: [ ],
        borderColor: 'rgba(127, 127, 127, 1)',
        borderWidth: 2,
        pointRadius: 0,
        fill: false,
      },
      {
        data: [ ],
        borderColor: 'rgba(156, 193, 83, 1)',
        backgroundColor: 'rgba(224, 236, 201, 1)',
        borderWidth: 4,
        pointRadius: 0,
        fill: 'origin',
      }
    ],
    labels: [ '' ]
  } ;
  trendOpts: ChartConfiguration['options'] = {
    maintainAspectRatio: false,
    responsive: true,
    font: {
      family: '"Gotham SSm A", "Gotham SSm B", "Arial", "Helvetica", sans-serif',
    },
    elements: {
      line: {
        tension: 0,
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          callback: function (value, index, values) {
            return `Week ${index + 1}` ;
          },
          font: {
            family: '"Gotham SSm A", "Gotham SSm B", "Arial", "Helvetica", sans-serif',
            size: 10,
          }
        },
      },
      y: {
        grid: {
          display: false
        },
        min: 0,
        max: 100,
        beginAtZero: true
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
  } ;
  breakdownType: ChartType = 'bar' ;
  breakdownData: ChartData<'bar'> = {
    labels: [ '0-19', '20-39', '40-59', '60-79', '80+' ],
    datasets: [
      {
        data: [],
        backgroundColor: [ '#0095DA', '#B2D235', '#949598', '#007236', '#80C342' ],
      }
    ]
  } ;
  breakdownOpts: ChartConfiguration['options'] = {
    maintainAspectRatio: true,
    responsive: true,
    font: {
      family: '"Gotham SSm A", "Gotham SSm B", "Arial", "Helvetica", sans-serif',
    },
    plugins: {
      title: {
        display: true,
        text: 'Distribution of Student Usage'
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display : false
        },
        title: {
          display: true,
          text: 'Weekly Usage Minutes'
        },
        ticks: {
          font: {
            family: '"Gotham SSm A", "Gotham SSm B", "Arial", "Helvetica", sans-serif',
          },
        },
      },
      y: {
        beginAtZero: true,
        min: 0,
        title: {
          display: true,
          text: 'Number of Students'
        },
        ticks: {
          font: {
            family: '"Gotham SSm A", "Gotham SSm B", "Arial", "Helvetica", sans-serif',
          },
        },
      },
    },
  } ;
  printIcon = faPrint ;

  @ViewChildren(BaseChartDirective) charts!: QueryList<BaseChartDirective> ;
  @ViewChild('usageTrendChart') trendChartCanvas!: ElementRef<HTMLCanvasElement> ;
  @ViewChild('usageTrendImg') trendChartImg!: ElementRef<HTMLImageElement> ;
  @ViewChild('breakdownChart') breakdownChartCanvas!: ElementRef<HTMLCanvasElement> ;
  @ViewChild('breakdownChartImg') breakdownChartImg!: ElementRef<HTMLImageElement> ;
  @ViewChild('printContent') printContent!: ElementRef<HTMLElement> ;

  constructor(
    private route: ActivatedRoute,
    private reportsService: ReportsService,
    private printService: PrintService,
    private sessionStorageService: SessionStorageService,
  ) { }

  ngOnInit(): void {
    this.currentUser = this.sessionStorageService.getUserData() ;
    this.showSchoolFilter = this.currentUser!.isSchoolUser() || this.currentUser!.isFILUser() || this.currentUser!.isDistrictUser() ;
    this.isSchoolReportsUser = this.currentUser ? this.currentUser?.isSchoolUser() : false;

    // Resolve data
    this.reportData = this.route.snapshot.data['resolveData'] ;
    this.rangeData = this.reportData.rangeData.schools ;
    this.dateRanges = this.reportData.ranges ;
    if (this.dateRanges.length)
    {
      this.dateRange = this.dateRanges[0] ;
    }
    else
    {
      this.dateRange = {
        startDisplay: '-',
        endDisplay: '-',
      } ;
    }
    this.areSessionsForAcademicYear = (this.dateRanges.length > 0) ;
    this.trendData.labels = this.reportData.trendLabels ;

    // Filter data
    this.districts = this.route.snapshot.data['filterData'].districts.filter((district: District) => district.name !== 'Clever District') ;
    this.schools = this.route.snapshot.data['filterData'].schools.filter((school: School) => school.name !== 'Clever School') ;
    this.schools.unshift({ schoolID: 0, districtID: 0, name: 'All', enabled: true }) ;
    this.teachers = this.route.snapshot.data['filterData'].teachers ;
    this.selectedDistrictId = this.reportsService.getSelectedDistrictForReports().districtID ;
    this.selectedSchoolId = this.reportsService.getSelectedSchoolForReports().schoolID ;

    // We call filter initially on Init because we might have a school previously selected from other reports and we might
    // have to then filter based on that. The Resolver ensures that our district data is correct (it uses the selected district to load)
    this.filterSchoolTeacher({ district: this.reportsService.getSelectedDistrictForReports(), school: this.reportsService.getSelectedSchoolForReports() }) ;
  }

  toggleTeacherTableSwitch() {
    this.teacherTableSwitch = (this.teacherTableSwitch === '+') ? '-' : '+' ;
    this.showTeacherTable = !this.showTeacherTable ;
  }

  toggleSchoolTableSwitch() {
    this.schoolTableSwitch = (this.schoolTableSwitch === '+') ? '-' : '+' ;
    this.showSchoolTable = !this.showSchoolTable ;
  }

  sortSchoolTable(sortOpts: any) {
    this.schoolAggregateData = (sortOpts.sortReverse) ? _.sortBy(this.schoolAggregateData, this.reportsService.sortSchoolFunction, sortOpts).reverse() : _.sortBy(this.schoolAggregateData, this.reportsService.sortSchoolFunction, sortOpts) ;
  }

  sortTeacherTable(sortOpts: any) {
    this.teacherAggregateData = (sortOpts.sortReverse) ? _.sortBy(this.teacherAggregateData, this.reportsService.sortTeacherFunction, sortOpts).reverse() : _.sortBy(this.teacherAggregateData, this.reportsService.sortTeacherFunction, sortOpts) ;
  }

  filterSchoolTeacher(filterOpts: any) {
    if (filterOpts.district.districtID !== this.selectedDistrictId)
    {
      // District was changed, so we need to load new report data
      this.selectedDistrictId = filterOpts.district.districtID ;
      this.selectedSchoolId = 0 ;
      this.reportsService.getDistrictUsageData().subscribe({
        next: (reportData: any) => {
          this.reportData = reportData ;
          this.rangeData = reportData.rangeData.schools ;
          this.dateRanges = reportData.ranges ;
          this.dateRange = reportData.ranges[0] ;

          // Now update our data on the report
          this.setOverallUsageData(this.reportData, this.selectedSchoolId) ;
          this.setRangeData(this.rangeData) ;
        }
      }) ;
    }
    else
    {
      // School updated
      this.selectedSchoolId = filterOpts.school.schoolID ;
      let filteredData: any = this.reportsService.filterDistrictReportData(this.rangeData, this.selectedSchoolId) ;

      this.setOverallUsageData(this.reportData, this.selectedSchoolId) ;
      this.setRangeData(filteredData) ;
    }
  }

  loadRangeData() {
    // Clear any previous data load error, if exists
    this.loadError = '' ;

    // Now get our new range data
    this.reportsService.getDistrictUsageRangeData(this.selectedDistrictId, this.dateRange.start, this.dateRange.end).subscribe({
      next: (rangeData: any) => {
        this.rangeData = rangeData.schools ;

        let filteredData = this.reportsService.filterDistrictReportData(this.rangeData, this.selectedSchoolId) ;
        this.setRangeData(filteredData) ;
      },
      error: () => {
        this.loadError = 'There was an error while trying to load Usage data for the selected date range. ' ;
        this.loadError += 'Please try again, if the problem continues please contact us for help.'
      }
    }) ;
  }

  setOverallUsageData(data: any, schoolId: number): void {
    let started = 0 ;
    let enrolled = 0 ;
    let schools: any = {} ;

    if (schoolId === 0)
    {
      schools = data.schools ;
    }
    else
    {
      schools[schoolId] = data.schools[schoolId] ;
    }

    Object.values(schools).forEach((school: any) => {
      if (school)
      {
        started += school.started ;
        enrolled += school.enrolled ;
      }
    }) ;

    // Update our labels for the new school with their sessions
    let trendLabels: any[] = [] ;
    data.weeklyUsage.forEach((val: any, idx: number) => {
      trendLabels.push(idx + 1) ;
    }) ;
    this.trendData.labels = trendLabels ;

    let startedPercent = (enrolled === 0) ? 0 : Math.round((started / enrolled) * 100) ;
    this.startedPercent = (startedPercent === 0 && enrolled > 0) ? '<1' : `${startedPercent}` ;
    this.enrolled = enrolled ;
    this.trendData.datasets[0].data = Array(data.weeklyUsage.length).fill(80) ;
    this.trendData.datasets[1].data = Array(data.weeklyUsage.length).fill(60) ;

    // For the usage trend, we need to handle the option that the selected school had no data, in that case make it 0's
    if (schoolId === 0)
    {
      this.trendData.datasets[2].data = data.weeklyUsage ;
    }
    else if (data.schools[schoolId])
    {
      this.trendData.datasets[2].data = data.schools[schoolId].weeklyUsage ;
    }
    else
    {
      this.trendData.datasets[2].data = Array(data.weeklyUsage.length).fill(0) ;
    }

    if (this.charts)
    {
      this.charts.first.update() ;
    }
  }

  setRangeData(data: any): void {
    // Set our school and teacher aggregate data
    let totalUsage = 0 ;
    let totalStudents = 0 ;
    let totalStudentsWithLogin = 0 ;
    let totalStudentsWithMultiLogin = 0 ;
    let schoolData: any[] = [] ;
    let teacherData: any[] = [] ;
    let breakdownData = [ 0, 0, 0, 0, 0 ] ;
    Object.values(data).forEach((school: any) => {
      let schoolStudents = 0 ;
      let schoolStudentsMeetingMin = 0 ;

      Object.values(school.teachers).forEach((teacher: any) => {
        schoolStudents += teacher.totalStudents ;
        schoolStudentsMeetingMin += teacher.totalStudentsMeetingMin ;

        // Determine the icon to use for our percentage for each teacher
        let teacherMeetingPercent = (teacher.totalStudents === 0) ? 0 : Math.round((teacher.totalStudentsMeetingMin / teacher.totalStudents) * 100) ;
        let teacherMeetingPercentDisplay = (teacherMeetingPercent === 0 && teacher.totalStudentsMeetingMin > 0) ? '<1' : teacherMeetingPercent ;
        let teacherMeetingIcon = '' ;
        if (teacherMeetingPercent <= 60)
        {
          teacherMeetingIcon = '<img src="/assets/images/reportIcons/iconLegendRed.svg" class="icon" />' ;
        }
        else if (teacherMeetingPercent <= 75)
        {
          teacherMeetingIcon = '<img src="/assets/images/reportIcons/iconLegendYellow.svg" class="icon" />' ;
        }
        else
        {
          teacherMeetingIcon = '<img src="/assets/images/reportIcons/iconLegendGreen.svg" class="icon" />' ;
        }

        teacherData.push({
          data: [
            teacher.firstName,
            teacher.lastName,
            teacher.grades,
            school.name,
            teacher.numberOfLogins,
            teacherMeetingPercent,
          ],
          display: [
            teacher.firstName,
            teacher.lastName,
            teacher.grades.join(', '),
            school.name,
            teacher.numberOfLogins,
            `<div class="icon-score">${teacherMeetingIcon} <div class="score">${teacherMeetingPercentDisplay}%</div></div>`,
          ],
        }) ;

        // We need to go through all students and bin their usage
        Object.values(teacher.students).forEach((student: any) => {
          // If we are displaying breakdown data for the 'All Ranges' category, then we average a students usage by number of weeks
          let studentUsage = student.usage ;

          if (studentUsage < 20) breakdownData[0]++ ;
          else if (studentUsage < 40) breakdownData[1]++ ;
          else if (studentUsage < 60) breakdownData[2]++ ;
          else if (studentUsage < 80) breakdownData[3]++ ;
          else if (studentUsage >= 80) breakdownData[4]++ ;

          // Increment aggregate statistics
          totalStudents++ ;
          totalUsage += studentUsage ;
          if (student.sessionCount > 0) totalStudentsWithLogin++ ;
          if (student.sessionCount > 1) totalStudentsWithMultiLogin++ ;
        }) ;
      }) ;

      // Determine the icon to use for our percentage for each school
      let schoolMeetingPercent = (schoolStudents === 0) ? 0 : Math.round((schoolStudentsMeetingMin / schoolStudents) * 100) ;
      let schoolMeetingPercentDisplay = (schoolMeetingPercent === 0 && schoolStudentsMeetingMin > 0) ? '<1' : schoolMeetingPercent ;
      let schoolMeetingIcon = '' ;
      if (schoolMeetingPercent < 60)
      {
        schoolMeetingIcon = '<img src="/assets/images/reportIcons/iconLegendRed.svg" class="icon" />' ;
      }
      else if (schoolMeetingPercent < 75)
      {
        schoolMeetingIcon = '<img src="/assets/images/reportIcons/iconLegendYellow.svg" class="icon" />' ;
      }
      else
      {
        schoolMeetingIcon = '<img src="/assets/images/reportIcons/iconLegendGreen.svg" class="icon" />' ;
      }

      schoolData.push({
        data: [
          school.name,
          Object.keys(school.teachers).length,
          Object.values(school.teachers).reduce((prev: any, cur: any) => { return prev += Object.keys(cur.students).length }, 0),
          schoolMeetingPercent,
        ],
        display: [
          school.name,
          Object.keys(school.teachers).length,
          Object.values(school.teachers).reduce((prev: any, cur: any) => { return prev += Object.keys(cur.students).length }, 0),
          `<div class="icon-score">${schoolMeetingIcon} <div class="score">${schoolMeetingPercentDisplay}%</div></div>`
        ],
      }) ;
    }) ;

    this.schoolAggregateData = schoolData ;
    this.teacherAggregateData = teacherData ;
    this.breakdownData.datasets[0].data = breakdownData ;
    this.avgWeeklyUsage = (this.enrolled === 0) ? 0 : Math.round(totalUsage / this.enrolled) ;
    if (this.charts) this.charts.last.update() ;

    let loginPercent = (this.enrolled === 0) ? 0 : Math.round((totalStudentsWithLogin / this.enrolled) * 100) ;
    this.loginPercentDisplay = (loginPercent === 0 && totalStudentsWithLogin > 0) ? '<1' : `${loginPercent}` ;

    let multiLoginPercent = (this.enrolled === 0) ? 0 : Math.round((totalStudentsWithMultiLogin / this.enrolled) * 100) ;
    this.multiLoginPercentDisplay = (multiLoginPercent === 0 && totalStudentsWithMultiLogin > 0) ? '<1' : `${multiLoginPercent}` ;
  }

  printReport() {
    // Convert charts to images before printing
    let trendCanvas = this.trendChartCanvas.nativeElement;
    let trendImg = this.trendChartImg.nativeElement;
    let breakdownCanvas = this.breakdownChartCanvas.nativeElement;
    let breakdownImg = this.breakdownChartImg.nativeElement;
    this.reportsService.convertCanvasToImage(trendCanvas, trendImg);
    this.reportsService.convertCanvasToImage(breakdownCanvas, breakdownImg);

    let school = this.reportsService.getSelectedSchoolForReports().name;
    let teacher = this.reportsService.getSelectedTeacherForReports();
    let teacherName = `${teacher.firstName} ${teacher.lastName}`;
    this.printService.openPrintWindow(this.printContent.nativeElement, school, teacherName);
  }
}
