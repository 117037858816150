<div #printContent id="printContent">
  <div class="print-hide" *ngIf="showSchoolFilter">
    <wf-school-teacher-filter [error]="schoolFilterError" [districts]="districts" [schools]="schools" [teachers]="teachers" [showDistrict]="currentUser!.isFILUser()" (filterChangeEvent)="updateStudentSessions()"></wf-school-teacher-filter>
  </div>
  <div  [ngClass]="{ 'disabled-report' : schoolFilterError }"></div>
  <div class="header-controls split-flex">
    <h3><img src="/assets/images/iconReports.svg" /> Reports</h3>
    <div class="print-hide filter-options">
      <wf-students-filter [grades]="grades" [classes]="classes" [filterExtraSupport]="true" (filterChangeEvent)="filterSessions($event)"></wf-students-filter>
    </div>
  </div>
  <div>
    <div class="print-hide alert message alert-danger" id="export-error" *ngIf="reportError">
      <div class="icon">
        <fa-icon [icon]="errorIcon"></fa-icon>
      </div>
      <div>
        <div class="bold">Report Export Error</div>
        {{ reportError }}
      </div>
    </div>
    <div class="report-section-header split-flex">
      <h5 class="results-report-header"><fa-icon [classes]="['print-hide']" [icon]="summaryIcon"></fa-icon> {{ fullProduct }}: Student Session Summary</h5>
      <div>
        <refresh-button
        (click)="refreshingTableData()"
        ></refresh-button>
        <button id="export-button" class="btn export-report-button print-hide" (click)="exportSessionSummary()" [disabled]="exportingReport">
          <fa-icon [icon]="excelIcon" *ngIf="!exportingReport"></fa-icon>
          <fa-icon [icon]="loadingIcon" [spin]="true" *ngIf="exportingReport"></fa-icon>
          Export Results
        </button>
        <button id="print-button" class="btn print-summary-button print-hide" (click)="printSummary()" *ngIf="!currentUser!.isTeacherReviewUser()"><fa-icon [icon]="printIcon"></fa-icon> Print Results</button>
      </div>
    </div>
  </div>
  <div class="flex column-gap-10 margin-top-10 margin-bottom-10">
    <div class="width-20">
      <label>Start Date</label>
      <div class="input-group print-hide">
        <input class="form-control" placeholder="mm/dd/yyyy" [minDate]="minDate" [maxDate]="maxDate" ngbDatepicker #start="ngbDatepicker" [(ngModel)]="startDate" (dateSelect)="updateStudentSessions()">
        <button class="btn btn-outline-secondary calendar" (click)="start.toggle()" type="button"><fa-icon [icon]="calendarIcon"></fa-icon></button>
      </div>
      <div class="print-show">{{startDate.year}}-{{startDate.month}}-{{startDate.day}}</div>
    </div>
    <div class="width-20">
      <label>End Date</label>
      <div class="input-group print-hide">
        <input class="form-control" placeholder="mm/dd/yyyy" [minDate]="minDate" [maxDate]="maxDate" ngbDatepicker #end="ngbDatepicker" [(ngModel)]="endDate" (dateSelect)="updateStudentSessions()">
        <button class="btn btn-outline-secondary calendar" (click)="end.toggle()" type="button"><fa-icon [icon]="calendarIcon"></fa-icon></button>
      </div>
      <div class="print-show">{{endDate.year}}-{{endDate.month}}-{{endDate.day}}</div>
    </div>
  </div>
  <table class="report-table">
    <thead>
      <tr>
        <th (click)="sortTable('startDate')">
          <div class="sortable-report-table-header">
            <div>Date</div>
            <div [ngClass]=" { 'arrow-up' : !sortReverse, 'arrow-down': sortReverse }" [style.visibility]="(sortColumn !== 'startDate') ? 'hidden' : 'visible'"></div>
          </div>
        </th>
        <th (click)="sortTable('lastName')">
          <div class="sortable-report-table-header">
            <div>Student Name</div>
            <div [ngClass]=" { 'arrow-up' : !sortReverse, 'arrow-down': sortReverse }" [style.visibility]="(sortColumn !== 'lastName') ? 'hidden' : 'visible'"></div>
          </div>
        </th>
        <th>
          <div class="sortable-report-table-header">
            <div>Start Time</div>
          </div>
        </th>
        <th>
          <div class="sortable-report-table-header">
            <div>End Time</div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let studentSession of filteredSessions">
        <td>{{ formatDate(studentSession.startDate) }}</td>
        <td>{{ studentSession.firstName }} {{ studentSession.lastName }}</td>
        <td>{{ formatTime(studentSession.startDate) }}</td>
        <td>{{ formatTime(studentSession.endDate) }}</td>
      </tr>
    </tbody>
  </table>
</div>
