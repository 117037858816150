import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { switchMap, flatMap, of } from 'rxjs';
import { Student } from '../core/models/student.model';
import { CleverService } from '../core/services/clever.service';
import { SessionStorageService } from '../core/services/session-storage.service';
import { LoginService } from '../core/services/login.service';
import { StudentsService } from '../core/services/students.service';

@Component({
  selector: 'wf-link-clever',
  templateUrl: './link-clever.component.html',
})
export class LinkCleverComponent implements OnInit {
  errorMessage: string = 'Error';
  showError: boolean = false;
  linkButtonDisabled: boolean = false;
  students: Student[] = [];

  // Link account form
  linkAccountForm = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });

  constructor(
    private loginService: LoginService,
    private studentService: StudentsService,
    private sessionStorageService: SessionStorageService,
    private cleverService: CleverService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  getStudents() {
    this.studentService.getStudents().subscribe({
      next: (response: any) => {
        if (response && !response.error) {
          this.students = response;
        }
      }
    });
  };

  createAccount() {
    this.showError = false;
    this.linkButtonDisabled = true;

    let username = this.sessionStorageService.getUsername();
    let ssoObject = this.sessionStorageService.getSSOInfo();

    if (ssoObject) {
    this.cleverService.createNewUser(ssoObject.bearerToken).pipe(
      switchMap((userData: any) => {
        if (ssoObject) {
          return this.loginService.login(userData.username, '', ssoObject)
        } else {
          return of({});
        }
      })).subscribe({
        next: () => {
          // When we are creating account, if this is a district user supported by SecureSync, we do not need to
          // roster our students as they will automatically be rostered for us. If this is a library user though,
          // allow them to choose the students to roster for their sections
          if (!ssoObject || ssoObject.authorizedBy !== 'district') {
            this.router.navigateByUrl('clever/roster');
          }
          else
          {
            // This is our first login, go to the resources
            this.router.navigateByUrl('/resources/screener/all/view/welcome', { state: { resourceUrl: 'html/welcome.html', videoUrl: '/assets/resources/videos/welcome.mp4' }}) ;
          }
        },
        error: (errRes: any) => {
          if (errRes && errRes.status === 401) {
            this.errorMessage = 'Could not authenticate';
            this.showError = true;
          }
          this.errorMessage = errRes?.message || 'An error has occurred.  Please try again later.';
          this.showError = true;
          this.linkButtonDisabled = false;
        }
      });
    }
  }

  linkAccount() {
    let username: string = this.linkAccountForm.get('username')?.value as string;
    let password: string = this.linkAccountForm.get('password')?.value as string;
    this.showError = false;
    this.linkButtonDisabled = true;

    let ssoObject = this.sessionStorageService.getSSOInfo();
    // remove sso so user logs in and uses basic auth interceptor
    this.sessionStorageService.removeSSOInfo();

    // First log in to see if the username password is valid and set the user into session
    this.loginService.login(username, password).pipe(
      flatMap((loginResponse: any) => {
        // User logged in, now link the account with clever
        if (ssoObject) {
          return this.cleverService.linkUser(ssoObject.bearerToken)
        } else {
          return of({});
        }
      }),
      flatMap((linkResponse: any) => {
        // Account linked successfully with basic auth,
        //  now add the sso credentials back in to the
        //  session storage.
        if (ssoObject) {
          this.sessionStorageService.setSSOInfo(ssoObject);
        }
        return this.studentService.getStudents();
      })).subscribe({
        // Based on the students,
        next: (students: Student[]) => {
          this.students = students;
          if (this.students && this.students.length > 0) {
            let userInfo = this.sessionStorageService.getUserData() ;
            if (userInfo && !userInfo.diagnosticProductEnabled && !userInfo.fullProductEnabled && (userInfo.numberOfUserLogins || 0) < 2)
            {
              // If this is our first login, go to the resources
              this.router.navigateByUrl('/resources/screener/view/welcome', { state: { resourceUrl: 'html/welcome.html', videoUrl: '/assets/resources/videos/welcome.mp4' }}) ;
            }
            else
            {
              // Go to students page.
              this.router.navigateByUrl('students');
            }
          }
          else if (!ssoObject || ssoObject.authorizedBy !== 'district')
          {
            this.router.navigateByUrl('clever/roster');
          }
        },
        error: (err: any) => {
          if (err && err.status === 401) {
            this.errorMessage = 'Invalid username and/or password';
            this.showError = true;
          }
          else if (err && err.status === 403) {
            this.errorMessage = 'Clever account could not be verified.  Unable to link accounts.';
            this.showError = true;
          }
          else {
            this.errorMessage = err?.message || 'An error has occurred.  Please try again later.';
            this.showError = true;
          }

          this.linkButtonDisabled = false;
        }
      })
  }
}
