import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NgChartsModule } from 'ng2-charts';
import { CookieService } from 'ngx-cookie-service' ;

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';

import { NgxCsvParserModule } from 'ngx-csv-parser';

import { ResourcesComponent } from './resources/resources.component';
import { ResourcesCategoryComponent } from './resources/category-select/category-select.component';
import { BasicAuthorizationInterceptor } from './core/interceptors/basic-authorization.interceptor';
import { CleverAuthorizationInterceptor } from './core/interceptors/clever-authorization.interceptor';
import { ClasslinkAuthorizationInterceptor } from './core/interceptors/classlink-authorization.interceptor';
import { LoginsModule } from './logins/logins.module';
import { SharedModule } from './shared/shared.module';
import { SettingsComponent } from './settings/settings.component';
import { EnrollmentComponent } from './enrollment/student-enrollment/enrollment.component';
import { StudentsModule } from './students/students.module';
import { ScreenerComponent } from './reports/screener/screener.component';
import { LinkCleverComponent } from './link-clever/link-clever.component';
import { RosterCleverComponent } from './roster-clever/roster-clever.component';
import { ParentDashboardComponent } from './reports/system/dashboards/parent/parent-dashboard.component';
import { SystemDashboardComponent } from './reports/system/dashboards/system/system-dashboard.component';
import { GroupSummaryComponent } from './reports/system/summary/group/group.component';
import { ObjectivePrePostComponent } from './reports/system/objective/objective.component';
import { SessionSummaryComponent } from './reports/system/summary/session/session.component';
import { UnitDetailComponent } from './reports/system/unit/detail/unit-detail.component';
import { AssignDiagnosticsComponent } from './subscriptions/assign-diagnostics/assign-diagnostics.component';
import { DiagnosticScheduleComponent } from './subscriptions/diagnostic-schedule/diagnostic-schedule.component';
import { DiagnosticReportComponent } from './reports/system/diagnostic/diagnostic.component';
import { DiagnosticDetailComponent } from './reports/system/diagnostic/detail/detail.component';
import { DistrictScreenerComponent } from './reports/district/screener/screener.component';
import { DistrictUsageComponent } from './reports/district/usage/usage.component';
import { DistrictProgressComponent } from './reports/district/progress/progress.component';
import { DistrictDiagnosticComponent } from './reports/district/diagnostic/diagnostic.component';
import { HtmlResourcesComponent } from './resources/html/html.component';
import { BatchEnrollmentComponent } from './enrollment/batch-enrollment/batch-enrollment.component';
import { ContactComponent } from './support/contact/contact.component';
import { SurveyComponent } from './survey/survey/survey.component';
import { StudentSummaryComponent } from './reports/system/summary/student/student.component';
import { KnowledgeBaseComponent } from './resources/knowledge-base/knowledge-base.component';
import { KnowledgeBaseItemComponent } from './resources/knowledge-base/item/item.component';
import { LinkClasslinkComponent } from './link-classlink/link-classlink.component';
import { RosterClasslinkComponent } from './roster-classlink/roster-classlink.component';
import { PdfViewerDialogComponent } from './resources/pdf-viewer/pdf-viewer-dialog.component';

@NgModule({
    declarations: [
        AppComponent,
        ResourcesComponent,
        ResourcesCategoryComponent,
        HtmlResourcesComponent,
        SettingsComponent,
        EnrollmentComponent,
        ScreenerComponent,
        ParentDashboardComponent,
        SystemDashboardComponent,
        LinkCleverComponent,
        RosterCleverComponent,
        GroupSummaryComponent,
        ObjectivePrePostComponent,
        SessionSummaryComponent,
        UnitDetailComponent,
        DiagnosticScheduleComponent,
        AssignDiagnosticsComponent,
        DiagnosticReportComponent,
        DiagnosticDetailComponent,
        DistrictScreenerComponent,
        DistrictUsageComponent,
        DistrictProgressComponent,
        DistrictDiagnosticComponent,
        BatchEnrollmentComponent,
        ContactComponent,
        SurveyComponent,
        StudentSummaryComponent,
        KnowledgeBaseComponent,
        KnowledgeBaseItemComponent,
        LinkClasslinkComponent,
        RosterClasslinkComponent,
        PdfViewerDialogComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        NgSelectModule,
        FormsModule,
        LoginsModule,
        StudentsModule,
        SharedModule,
        FontAwesomeModule,
        NgChartsModule,
        BrowserAnimationsModule,
        NgbModule,
        VgCoreModule,
        VgBufferingModule,
        VgControlsModule,
        VgOverlayPlayModule,
        NgxCsvParserModule,
    ],
    providers: [
        CookieService,
        {provide: HTTP_INTERCEPTORS, useClass: BasicAuthorizationInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: CleverAuthorizationInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ClasslinkAuthorizationInterceptor, multi: true},
        AppRoutingModule,
        ReactiveFormsModule,
        HttpClientModule,
        {
        provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        }, {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
