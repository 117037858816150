import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged, filter, fromEvent, Observable, of, Subscription, switchMap, throwError } from 'rxjs';

import { faCircleExclamation, faCircleInfo, faLock, faSpinner, faUser, faSquareCaretUp } from '@fortawesome/free-solid-svg-icons';

import { LoginService } from '../../core/services/login.service';
import { SessionStorageService } from "../../core/services/session-storage.service";
import { LocalStorageService } from '../../core/services/local-storage.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'wf-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  spinnerIcon = faSpinner ;
  loginErrorIcon = faCircleExclamation ;
  userIcon = faUser ;
  passwordIcon = faLock ;
  capsLockIcon = faSquareCaretUp ;
  capsOn: boolean = false;
  result: string | null = null ;
  loading: boolean = false ;
  returnedLoginError: boolean = false ;
  returnedVersionError: boolean = false ;
  returnedError: boolean = false ;
  returnTitle: string | null = null ;

  lastUsername: string | null = this.localService.getLastUsername();
  rememberMe: boolean = !!this.localService.getLastUsername();
  loginForm = new FormGroup({
    username: new FormControl({ value: '', disabled: this.loading }, Validators.required),
    password: new FormControl({ value: '', disabled: this.loading }, Validators.required),
    rememberMe: new FormControl({ value: false, disabled: this.loading }),
  })
  displayForm: boolean = true;
  showProviderButtons: boolean = false;
  cleverOauth = `https://clever.com/oauth/authorize?response_type=code&redirect_uri=${encodeURIComponent(environment.CLEVER_REDIRECT_URI)}&client_id=${environment.CLEVER_CLIENT_ID}` ;
  classlinkOauth = `https://launchpad.classlink.com/oauth2/v2/auth?scope=profile,oneroster,full&redirect_uri=${encodeURIComponent(environment.CLASSLINK_REDIRECT_URI)}&client_id=${environment.CLASSLINK_CLIENT_ID}&response_type=code` ;

  private keyPressListener$: Observable<KeyboardEvent> ;
  private keyPressSubscription: Subscription | null = null ;

  constructor(
    private loginService: LoginService,
    private sessionService: SessionStorageService,
    private localService: LocalStorageService,
    private router: Router,
  ) {
    this.keyPressListener$ = fromEvent<KeyboardEvent>(window, 'keydown').pipe(
      filter((e: KeyboardEvent) => e.code === 'CapsLock'),
      distinctUntilChanged()
    ) ;
  }

  ngOnInit(): void {
    if (this.lastUsername) {
      this.loginForm.controls['username'].setValue(this.lastUsername)
      this.loginForm.controls['rememberMe'].setValue(this.rememberMe)
    }

    this.keyPressSubscription = this.keyPressListener$.subscribe(() => {
      this.capsOn = !this.capsOn ;
    }) ;

    if (this.sessionService.getLogoutMessage())
    {
      this.loading = false ;
      this.returnedLoginError = true ;
      this.returnTitle = 'Not Logged In'
      this.returnedError = true ;
      this.loginErrorIcon = faCircleExclamation ;
      this.result = this.sessionService.getLogoutMessage() ;

      this.sessionService.setLogoutMessage('') ;
    }
  }

  ngOnDestroy() {
    this.keyPressSubscription?.unsubscribe() ;
  }

  login() {
    this.loading = true ;
    this.loginForm.disable() ;

    this.loginService.checkForUpdate().pipe(
      switchMap(updateRequired => {
        if (updateRequired)
        {
          this.result = "A new version of the management application is available. Please login again...";
          this.returnedVersionError = true;
          this.returnTitle = 'Outdated Version'
          this.returnedError = true;
          this.loginErrorIcon = faCircleInfo ;
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
        return of(updateRequired);
      }),
      filter(updateRequired => !updateRequired),
      switchMap(() => this.loginService.login(this.loginForm.get('username')?.value as string, this.loginForm.get('password')?.value as string))
    ).subscribe({
      next: (response) => {
        this.loading = false ;
        this.returnedLoginError = false ;
        this.returnedVersionError = false ;
        this.result = '' ;
        this.returnTitle = '';
        this.returnedError = false ;
        this.loginForm.enable() ;

        if (this.loginForm.get('rememberMe')?.value) {
          this.localService.setLastUsername(this.loginForm.get('username')?.value as string)
        } else {
          this.localService.removeLastUsername()
        }

        if (response.isLoggedIn)
        {
          this.displayForm = false
          this.loginService.redirectAfterLogin(this.sessionService.getUserData(), response) ;
        }
      },
      error: (error) => {
        let errorCode = error.status ?? -1 ;
        this.loading = false ;
        this.returnedLoginError = true ;
        this.returnTitle = 'Login Failed'
        this.returnedError = true ;
        this.loginErrorIcon = faCircleExclamation ;
        this.loginForm.enable() ;

        if (errorCode === 401)
        {
          this.result = 'The username or password you entered is incorrect' ;
        }
        else if (errorCode === 406)
        {
          this.showProviderButtons = true ;
          this.result = 'Looks like that didn\'t work. Would you like to try logging in with another provider?' ;
        }
        else if (errorCode >= 500 || errorCode === -1)
        {
          this.result = 'The application is temporarily unavailable. Please wait a few moments and try again'
        }
      }
    }) ;
  }
}
