import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';

import {
  faArrowRotateLeft, faCircleExclamation,
  faTrashCan,
  faUpload,
  faUserGraduate,
  faUserGroup
} from '@fortawesome/free-solid-svg-icons';

import { SchoolClass } from 'src/app/core/models/school-class.model';
import { Student } from 'src/app/core/models/student.model';
import { EnrollmentService } from 'src/app/core/services/enrollment.service';
import { StudentsService } from 'src/app/core/services/students.service';

@Component({
  selector: 'app-batch-enrollment',
  templateUrl: './batch-enrollment.component.html',
})
export class BatchEnrollmentComponent implements OnInit {

  studentList: Student[] = [];
  errors: string[] = [] ;
  fileName: string = 'Import CSV';
  allGrades: string[] = [];
  selectedGrade: string = '';
  classes: SchoolClass[] = [];
  invalidBatch: boolean = false;
  csvPreview: any = [];
  csvHeaders: any = [];
  csvRows: any = [];
  showCsvPreview: boolean = false;
  enableEnrollButton: boolean = false;
  previewNote: string = "";
  importIcon = faUpload ;
  removeIcon = faTrashCan ;
  undoIcon = faArrowRotateLeft ;
  enrollIcon = faUserGroup ;
  studentIcon = faUserGraduate ;
  errorIcon = faCircleExclamation ;

  @ViewChild('studentCSVUpload') studentCSVUpload!: ElementRef<HTMLInputElement>

  constructor(
    private enrollmentService: EnrollmentService,
    private studentsService: StudentsService,
    private csvParser: NgxCsvParser,
  ) { }

  ngOnInit(): void {
  }

  handleFileInput(event: any): void {
    this.showCsvPreview = true;
    this.enableEnrollButton = true;
    const files = event.srcElement.files;
    this.fileName = files[0].name;
    if (files[0].type === 'text/csv') {
      this.previewNote = "Parsed CSV file preview. Click \"Enroll\" to enroll the following students.";
      this.csvParser.parse(files[0], { header: false, delimiter: ',' })
        .pipe().subscribe({
          next: (result) => {
            this.csvPreview = result;
            this.csvHeaders = this.csvPreview[0];
            this.csvRows = this.csvPreview.slice(1);
          }
        });
    } else {
      this.previewNote = "File preview is unavailable";
    }
  }

  removeFile(): void {
    this.studentCSVUpload.nativeElement.value = "";
    this.fileName = "Import CSV";
    this.csvPreview = [];
    this.csvHeaders = [];
    this.csvRows = [];
    this.showCsvPreview = false;
    this.enableEnrollButton = false;
  }

  addStudents() {
    this.showCsvPreview = false;
    this.enableEnrollButton = false;
    let inputFiles = this.studentCSVUpload.nativeElement.files;
    let data = new FormData();
    if (inputFiles) {
      data.append('file', inputFiles[0]);
    }
    this.enrollmentService.addStudentList(data).subscribe({
      next: (res: any) => {
        this.invalidBatch = false;
        this.errors = [];
        this.getStudents();
        this.removeFile();
      },
      error: (res: any) => {
        this.invalidBatch = true;
        this.errors = res.error.message.split('\n').filter((err: any) => err.length ) ;
      }
    });
  }

  getStudents() {
    this.studentsService.getStudents().subscribe({
      next: (students: Student[]) => {
        this.studentList = students;
      }
    });
  }

  undoBatchEnrollment() {
    this.enrollmentService.undoBatchEnrollment().subscribe({
      next: () => {
        this.invalidBatch = false;
        this.errors = [];
        this.getStudents();
      },
      error: () => {
        this.invalidBatch = true;
        this.errors = [ 'Unable to Undo: batch enrolled students already have active sessions.' ] ;
        this.getStudents();
      }
    });
  }

}
