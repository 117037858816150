import { Component, OnInit } from '@angular/core';
import { Student } from '../core/models/student.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../core/services/login.service';
import { StudentsService } from '../core/services/students.service';
import { SessionStorageService } from '../core/services/session-storage.service';
import { ClasslinkService } from '../core/services/classlink.service';
import { Router } from '@angular/router';
import { flatMap, of, switchMap } from 'rxjs';

@Component({
  selector: 'wf-link-classlink',
  templateUrl: './link-classlink.component.html',
  styleUrls: ['./link-classlink.component.css']
})
export class LinkClasslinkComponent implements OnInit {
  errorMessage: string = 'Error';
  showError: boolean = false;
  linkButtonDisabled: boolean = false;
  students: Student[] = [];

  // Link account form
  linkAccountForm = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });

  constructor(
    private loginService: LoginService,
    private studentService: StudentsService,
    private sessionStorageService: SessionStorageService,
    private classlinkService: ClasslinkService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  linkAccount() {
    let username: string = this.linkAccountForm.get('username')?.value as string;
    let password: string = this.linkAccountForm.get('password')?.value as string;
    this.showError = false;
    this.linkButtonDisabled = true;

    let ssoObject = this.sessionStorageService.getSSOInfo();
    // remove sso so user logs in and uses basic auth interceptor
    this.sessionStorageService.removeSSOInfo();

    // First log in to see if the username password is valid and set the user into session
    this.loginService.login(username, password).pipe(
      flatMap((loginResponse: any) => {
        // User logged in, now link the account with clever
        if (ssoObject) {
          return this.classlinkService.linkUser(ssoObject.bearerToken)
        } else {
          return of({});
        }
      }),
      flatMap((linkResponse: any) => {
        // Account linked successfully with basic auth,
        //  now add the sso credentials back in to the
        //  session storage.
        if (ssoObject) {
          this.sessionStorageService.setSSOInfo(ssoObject);
        }
        return this.studentService.getStudents();
      })).subscribe({
      // Based on the students,
      next: (students: Student[]) => {
        this.students = students;
        let managementUser = this.sessionStorageService.getUserData() ;
        if (managementUser === null)
        {
          this.router.navigateByUrl('/resources/screener/all/view/welcome', { state: { resourceUrl: 'html/welcome.html', videoUrl: '/assets/resources/videos/welcome.mp4' }}) ;
        }
        else if (managementUser.isCustomerServiceUser())
        {
          this.router.navigateByUrl('/batch-enrollment') ;
        }
        else if (!managementUser.fullProductEnabled && !managementUser.diagnosticProductEnabled && (!managementUser.numberOfUserLogins || managementUser.numberOfUserLogins! < 2))
        {
          this.router.navigateByUrl('/resources/screener/all/view/welcome', { state: { resourceUrl: 'html/welcome.html', videoUrl: '/assets/resources/videos/welcome.mp4' }}) ;
        }
        else if (managementUser.isTeacherUser() || managementUser.isParentUser())
        {
          this.router.navigateByUrl('/students') ;
        }
        else if (managementUser.isSchoolUser())
        {
          this.router.navigateByUrl('/reports/screener') ;
        }
        else if (managementUser.isDistrictUser() || managementUser.isFILUser())
        {
          this.router.navigateByUrl('/reports/district/screener') ;
        }
      },
      error: (err: any) => {
        if (err && err.status === 401) {
          this.errorMessage = 'Invalid username and/or password';
          this.showError = true;
        }
        else if (err && err.status === 403) {
          this.errorMessage = 'Classlink account could not be verified.  Unable to link accounts.';
          this.showError = true;
        }
        else {
          this.errorMessage = err?.message || 'An error has occurred.  Please try again later.';
          this.showError = true;
        }

        this.linkButtonDisabled = false;
      }
    })
  }

  createAccount() {
    this.showError = false;
    this.linkButtonDisabled = true;

    let username = this.sessionStorageService.getUsername();
    let ssoObject = this.sessionStorageService.getSSOInfo();

    if (ssoObject) {
      this.classlinkService.createNewUser(ssoObject.bearerToken).pipe(
        switchMap((userData: any) => {
          if (ssoObject) {
            return this.loginService.login(userData.username, '', ssoObject)
          } else {
            return of({});
          }
        })).subscribe({
        next: () => {
          // This is our first login, go to the resources
          this.router.navigateByUrl('/resources/screener/all/view/welcome', { state: { resourceUrl: 'html/welcome.html', videoUrl: '/assets/resources/videos/welcome.mp4' }}) ;
        },
        error: (errRes: any) => {
          if (errRes && errRes.status === 401) {
            this.errorMessage = 'Could not authenticate';
            this.showError = true;
          }
          this.errorMessage = errRes?.message || 'An error has occurred.  Please try again later.';
          this.showError = true;
          this.linkButtonDisabled = false;
        }
      });
    }
  }
}
