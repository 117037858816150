import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';
import {
  ReplaySubject,
  catchError,
  map,
  Observable,
  of,
  switchMap,
} from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

import { SessionStorageService } from './session-storage.service';
import { LocalStorageService } from './local-storage.service';
import { SessionTimerService } from './session-timer.service';
import { ManagementSessionService } from './management-session.service';
import { UserRoles } from '../models/user.model';
import { ManagementUser, ManagementUserAdapter } from '../models/management-user.model';
import { SSOCredentials } from 'src/app/core/models/credentials.model';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private loginStatusSource = new ReplaySubject<boolean>();
  loginStatus$ = this.loginStatusSource.asObservable();

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private managementAdapter: ManagementUserAdapter,
    private sessionStorageService: SessionStorageService,
    private sessionTimerService: SessionTimerService,
    private managementSessionService: ManagementSessionService,
    private localStorageService: LocalStorageService,
    private cookieService: CookieService
  ) {}

  // TODO: maybe make an sso interface?
  login(username: string, password: string, sso?: SSOCredentials) {
    this.sessionStorageService.clear();
    this.sessionStorageService.setUsername(username);
    this.sessionStorageService.setPassword(password);
    this.sessionStorageService.setLoginTime((new Date()).getTime()) ;
    if (sso) {
      this.sessionStorageService.setSSOInfo(sso);
    }

    // Construct our login request
    const reqOpts = {
      withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'X-FIL-Version': environment.versionNumber,
      },
    };

    return this.httpClient
      .get(environment.dataServicesURL + 'manage/login', reqOpts)
      .pipe(
        map((res: any) => {
          let loginData = {
            data: res,
            isLoggedIn: true,
          };

          // Put our data in the session
          this.sessionStorageService.setUserData(
            this.managementAdapter.adapt(res)
          );
          this.sessionStorageService.setUserLoggedIn(true);
          this.cookieService.set('username', res.username);

          // Start our inactivity timer
          this.sessionTimerService.startSleepAndInactivityTimers();

          // Set our login status
          this.notifyLoginStatus(true);

          return loginData;
        })
      );
  }

  logout(skipSessions: boolean = false, logoutMsg: string = '') {
    if (!skipSessions)
    {
      // End the last page session, write session data to the database, and clear the session data
      // this.sessionTimerService.endPageSession(this.previousPage);
      this.managementSessionService.saveManagementSession(this.sessionTimerService.getPageSessions()).subscribe();
    }

    // this.sessionStorageService.clearSessionData();

    this.sessionTimerService.cancelTimers();
    this.sessionStorageService.clear();
    this.sessionTimerService.clearPageSessions();
    this.cookieService.delete('username');
    this.notifyLoginStatus(false);

    if (logoutMsg)
    {
      this.sessionStorageService.setLogoutMessage(logoutMsg) ;
    }
  }

  checkForUpdate(): Observable<boolean> {
    let now: number = Date.now();
    let checkForUpdateTime: number | null =
      this.localStorageService.getCheckForUpdateTime();

    if (!checkForUpdateTime || now > checkForUpdateTime) {
      // We are checking for update now, so set that time
      this.localStorageService.setCheckForUpdateTime(
        new Date(now + 3600000).valueOf()
      );

      let reqOptions = {
        withCredentials: false,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'X-FIL-Version': environment.versionNumber,
        },
      };

      return this.httpClient
        .get(`${environment.dataServicesURL}manage/checkForUpdate`, reqOptions)
        .pipe(
          switchMap((res) => {
            // No update needed with successful response
            return of(false);
          }),
          catchError((err: any) => {
            // Update needed with error code 426
            return of(err?.status === 426);
          })
        );
    } else {
      // No check needed, indicate so
      return of(false);
    }
  }

  changePassword(
    currentPassword: string,
    newPassword: string
  ): Observable<any> {
    // Safety check
    if (currentPassword.trim() === '' || newPassword.trim() === '') {
      return of(-1);
    }

    let reqOptions = {
      withCredentials: true,
      observe: 'response',
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    };

    let data = {
      oldpasswd: currentPassword,
      newpasswd: newPassword,
    };

    return this.httpClient
      .post(
        `${environment.dataServicesURL}manage/enroll/teacher/password/update`,
        data,
        reqOptions as any
      )
      .pipe(
        map((res: any) => {
          // We were successful, so update our password in storage
          this.sessionStorageService.setPassword(newPassword);

          return res.status;
        }),
        catchError((error) => {
          return of(error.status);
        })
      );
  }

  changePasswordViaToken(token: string, newPassword: string): Observable<any> {
    let reqOptions = {
      withCredentials: false,
      observe: 'response',
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    };

    let data = {
      token: token,
      newpasswd: newPassword,
    };

    return this.httpClient
      .post(
        `${environment.dataServicesURL}manage/enroll/teacher/password/reset`,
        data,
        reqOptions as any
      )
      .pipe(
        map((res: any) => {
          return res.status;
        }),
        catchError((error) => {
          return of(error.status);
        })
      );
  }

  requestPasswordReset(userEmail: string): Observable<any> {
    let reqOptions = {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    };

    return this.httpClient.put(
      `${environment.dataServicesURL}manage/requestPasswordReset`,
      userEmail,
      reqOptions
    );
  }

  notifyLoginStatus(status: boolean) {
    this.loginStatusSource.next(status);
  }

  /**
   * Redirect the user after successfully logging in to the correct landing page
   * @param managementUser
   * @param loginResponse
   */
  redirectAfterLogin(managementUser: ManagementUser | null, loginResponse: any) {
    if (!managementUser)
    {
      // This should not happen, but just in case
      this.router.navigateByUrl('/resources/screener/all/view/welcome', {
        state: {
          resourceUrl: 'html/welcome.html',
          videoUrl: '/assets/resources/videos/welcome.mp4'
        }
      }) ;

      return ;
    }

    // Our login landing is a bit complex dependent on the user and other conditions:
    // 1. If user is a customer service user, this is a FIL user that will provide batch enrollment so land there
    // 2. If this is a teacher screener user (no diagnostic or full product) and they have logged in less than 2 times, go to the welcome page w/video
    // 3. If this is a teacher system subscription with enrolled students, land them on the dashboard
    // 4. If this is a teacher with no students enrolled or non-system subscription, land them at enrollment list (to enroll)
    // 5. If this is a school user, land at detailed screener reports
    // 6. If this is a district or FIL user, land at district screener reports
    if (managementUser.isCustomerServiceUser())
    {
      this.router.navigateByUrl('/batch-enrollment') ;
    }
    else if (managementUser.isTeacherUser())
    {
      if (!loginResponse.data.fullProductEnabled && !loginResponse.data.diagnosticProductEnabled && loginResponse.data.numberOfUserLogins < 2)
      {
        // Screener
        this.router.navigateByUrl('/resources/screener/all/view/welcome', { state: { resourceUrl: 'html/welcome.html', videoUrl: '/assets/resources/videos/welcome.mp4' }}) ;
      }
      else if (loginResponse.data.fullProductEnabled && loginResponse.data.hasEnrolled)
      {
        // System subscription with enrolled students
        this.router.navigateByUrl('/dashboard/system') ;
      }
      else
      {
        // No enrolled students or not a system subscription
        this.router.navigateByUrl('/students') ;
      }
    }
    else if (managementUser.isParentUser())
    {
      this.router.navigateByUrl('/students') ;
    }
    else if (managementUser.isSchoolUser())
    {
      this.router.navigateByUrl('/reports/screener') ;
    }
    else if ((managementUser.isDistrictUser() || managementUser.isFILUser()))
    {
      this.router.navigateByUrl('/reports/district/screener') ;
    }
  }
}
