import { Component, OnInit } from '@angular/core';
import { ClasslinkService } from '../core/services/classlink.service';
import { EnrollmentService } from '../core/services/enrollment.service';
import {
  faArrowRotateBack,
  faCheckCircle,
  faCircleExclamation,
  faCircleInfo,
  faCirclePlus,
  faSpinner, faTriangleExclamation,
  faUserGraduate, faUserPlus
} from '@fortawesome/free-solid-svg-icons';

// CONSTANTS
const OTHER_GRADE_VAL = 14;

@Component({
  selector: 'app-roster-classlink',
  templateUrl: './roster-classlink.component.html',
  styleUrls: ['./roster-classlink.component.css']
})
export class RosterClasslinkComponent implements OnInit {
  showError: boolean = false;
  loading: boolean = false ;
  isAllSelected: boolean = false;
  messageTitle: string = '' ;
  message: string = '' ;
  messageStatus: string = '' ;
  gradeOptions: any[] = [];
  classData: any[] = [];
  rosterClasses: any[] = [];
  studentIcon = faUserGraduate ;
  rosterIcon = faUserPlus ;
  backIcon = faArrowRotateBack ;
  loadingIcon = faSpinner ;
  successIcon = faCheckCircle ;
  errorIcon = faCircleExclamation ;
  infoIcon = faCircleInfo ;
  warningIcon = faTriangleExclamation ;

  constructor(
    private enrollmentService: EnrollmentService,
    private classlinkService: ClasslinkService,
  ) { }

  ngOnInit(): void {
    this.loading = true ;

    // TODO: These should be piped, or put in a resolver?
    this.enrollmentService.getGradeOptions().subscribe((data: any) => {
      for (let i = 0; i < data.length; i++) {
        this.gradeOptions.push(data[i]);
      }
    });

    this.classlinkService.getClasses().subscribe({
      next: (response: any) => {
        this.classData = JSON.parse(response.data) ;

        this.classData.forEach((cData) => {
          let gradeVal: number = -1 ;
          if (cData.grades.length)
          {
            // a ClassLink class has an array of grades, not a singular grade, we will attempt to find the
            // first one that matches our available grade options in WF
            for (let idx = 0 ; idx < cData.grades.length ; idx++)
            {
              const cDataGradeVal = parseInt(cData.grades[idx]);
              for (let key in this.gradeOptions)
              {
                if (this.gradeOptions[key].value === cData.grades[idx] || cDataGradeVal === parseInt(this.gradeOptions[key].value))
                {
                  gradeVal = this.gradeOptions[key].value ;
                  break ;
                }
              }

              if (gradeVal > 0) break ;
            }
          }

          if (gradeVal < 0) gradeVal = OTHER_GRADE_VAL ;
          this.rosterClasses.push({
            name: cData.title || cData.sourcedId,
            id: cData.sourcedId,
            grade: { value: gradeVal, disabled: true },
            checked: false,
          }) ;
        }) ;

        this.loading = false ;
      },
      error: () => {
        this.message = "We are unable to retrieve your ClassLink class information at this time.";
        this.messageStatus = 'error' ;
        this.messageTitle = 'Something went wrong' ;
        this.loading = false;
        this.showError = true;
      }
    });
  }

  toggleAll(event: any) {
    let toggleStatus = event.target.checked;
    this.rosterClasses.forEach((classData) => {
      classData.checked = toggleStatus ;
      classData.grade.disabled = !toggleStatus ;
    });

    this.isAllSelected = toggleStatus;
  }

  selectClass(e: any) {
    const cData = this.rosterClasses.find((data) => data.id === e.target.value) ;
    if (cData)
    {
      cData.grade.disabled = !e.target.checked ;
      cData.checked = e.target.checked ;
    }
  }

  submitClasses() {
    this.loading = true ;
    this.message = '' ;
    const selectedClasses = this.rosterClasses.filter((cData) => cData.checked) ;
    if (selectedClasses.length === 0) return ;

    this.classlinkService.rosterClasses(selectedClasses).subscribe({
      next: (response: any) => {
        if (response.duplicates && response.duplicateStudents && response.duplicateStudents.length)
        {
          this.messageTitle = 'Classes Rostered' ;
          this.messageStatus = 'warning' ;
          this.message = 'Students from your selected Classes were rostered. However, the following Students already had '
            + 'accounts and were skipped during this rostering:' ;

          this.message += '<ul>' ;
          response.duplicateStudents.forEach((dupe: any) => {
            this.message += `<li>${dupe.FirstName} ${dupe.LastName}</li>` ;
          }) ;
          this.message += '</ul>' ;
        }
        else
        {
          this.messageTitle = 'Classes Rostered' ;
          this.messageStatus = 'success' ;
          this.message = 'Students from your selected Classes where successfully rostered' ;
        }

        this.loading = false ;
      },
      error:() => {
        this.message = 'Something went wrong while trying to roster the selected Classes. Please let us know and ask for assistance!' ;
        this.messageStatus = 'error' ;
        this.messageTitle = 'Could not Roster Classes' ;
        this.loading = false ;
      }
    }) ;
  }
}
